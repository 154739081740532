/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Michigan is one of America’s liveliest, most exciting states. But it’s also a place where hearing loss is a major factor. In fact, Because of the state’s love of rock and soul music, it’s passion for automobiles, and the prosperity of factories over the years in cities like Detroit, Michigan is a place where many millions of Americans suffer from hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even if they won’t admit it, many residents of the Great Lakes State have hearing problems that can be resolved with the right technical and medical interventions. It’s well worth taking the plunge. Think about it. If you love to attend Wolverines matches against Ohio, and you adore the intensity of the atmosphere, isn’t it sad to hear the crowd noise fade a little more every time you attend?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And if you listen to metal bands at Harpos or soul at the Fillmore in Detroit, don’t you want to hear the music as clearly as you used to? Healthy hearing is something that we tend to take for granted, but it really makes an impact on our quality of life when it starts to fail–and that’s especially the case in a state like Michigan."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "noise-sources-in-michigan",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noise-sources-in-michigan",
    "aria-label": "noise sources in michigan permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noise sources in Michigan"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The thing is, Michigan is a state with more potential hearing risks than most. For one thing, it was the birthplace of the automobile industry (not to mention a hub for steel and engineering industries). That means millions of Wolverines have worked for prolonged periods of time in noisy factories or as professional drivers, exposing their ears to potentially dangerous levels of noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And if you’re one of them, you might not even realize how badly your ears have suffered. Studies have found that almost half of active factory workers tend to suffer from hearing loss, while three-quarters of them report “excellent hearing”. But factories aren’t alone in threatening Michigan’s hearing. Even driving the highways for decades can gradually damage the nerves in your auditory system, while packed sporting venues like Ann Arbor’s Michigan Stadium can generate over 140 decibels of noise (when 85 decibels is generally seen as a dangerous level)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And shooting is another common hearing risk in the Great Lakes State. Over 600,000 residents have concealed pistol licenses, while some 580,000 Wolverines report hunting at least once a year. Gunshots average between 140-190 decibels, and many hunters fail to wear ear protection, so it’s not surprising to find that hunters experience hearing loss in greater numbers than the general population. It’s just another area where Michigan’s rich social life poses risks to the state’s hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-numbers-behind-hearing-loss-in-the-great-lakes-state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-numbers-behind-hearing-loss-in-the-great-lakes-state",
    "aria-label": "the numbers behind hearing loss in the great lakes state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The numbers behind hearing loss in the Great Lakes State"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It might help to put hearing loss in Michigan into perspective with a few statistics. That way, you’ll see that you are not alone if you start to experience the symptoms of chronic hearing loss. In fact, out of a population of almost 10 million, between 1-2 million residents are dealing with some degree of hearing loss right now."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They might not have disabling hearing loss (although over 25 percent of seniors do), but it’s likely to be affecting the way they live from day to day. What’s surprising is how few of those people have taken action to solve their hearing loss issues. As the Michigan State Association of the Hearing Loss Association of America reports, only around 200,000 of 1.4 million people in the state have sought a hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They also report that around 420,000 people have reported hearing loss due to noise exposure at work – so over 200,000 of those individuals are choosing to deal with hearing loss without the help of modern technology. At hear.com, that’s a situation we hope to change."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom---helping-michiganders-with-their-hearing-loss-needs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom---helping-michiganders-with-their-hearing-loss-needs",
    "aria-label": "hearcom   helping michiganders with their hearing loss needs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com - helping Michiganders with their hearing loss needs"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com provides a statewide service for Michigan residents who can benefit from using a hearing aid. A surprising percentage of people who experience mild to severe hearing loss can correct their hearing with the latest devices, so symptoms you’re experiencing, we can help."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our team manages a network of more than 80 Partner Providers across the state. If you work for the State in Lansing, teach classes in Ann Arbor or put long hours in at Detroit’s factories, there will be an expert audiologist or hearing specialist who can help you find the ideal hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-in-touch-and-resolve-your-hearing-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-in-touch-and-resolve-your-hearing-problems",
    "aria-label": "get in touch and resolve your hearing problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get in touch and resolve your hearing problems"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hear.com service is simple and accessible. We will carry out a quick consultation to suggest some appropriate hearing aid models, then arrange a one-to-one appointment with an expert to have your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing tested"), " and devices fitted. After that, you can try them for 45 days risk free. If this sounds good to you, fill out our questionnaire today and get started on the road to better hearing. We can’t wait to help."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
